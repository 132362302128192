import React from "react";
// Layouts
import DefaultLayout from "./layouts/default";
// Components
import BaseButton from "./components/base/Button";
import BaseSection from "./components/base/Section";
import BaseAccordion from "./components/base/Accordion";
import LandingCryptoStatistic from "./components/landing/CryptoStatistic";
import LandingExchange from "./components/landing/Exchange";
import LandingListItem from "./components/landing/ListItem";
import LandingStep from "./components/landing/Step";

function App() {
  const trendings = [
    {
      id: 1,
      name: "Bitcoin",
      price: 43180.13,
      logo: "bitcoin.png",
      increase: true,
      data: [40, 35, 60, 75, 60, 75, 50],
    },
    {
      id: 2,
      name: "Ethereum",
      price: 3480.65,
      logo: "ethereum.png",
      increase: false,
      data: [25, 30, 60, 50, 80, 55, 80],
    },
    {
      id: 3,
      name: "Solana",
      price: 150.2,
      logo: "solana.png",
      increase: true,
      data: [40, 45, 40, 80, 50, 60, 35],
    },
    {
      id: 4,
      name: "Dogecoin",
      price: 0.1572,
      logo: "dogecoin.png",
      increase: true,
      data: [35, 70, 60, 80, 50, 60, 40],
    },
  ];
  const topGainers = [
    {
      id: 1,
      name: "PAPPAY",
      price: 0.00374,
      logo: "pappay.png",
      increase: true,
      data: [30, 50, 45, 60, 70, 40, 45],
    },
    {
      id: 2,
      name: "Bitcoin Asia",
      price: 0.02096,
      logo: "bitcoin-asia.png",
      increase: true,
      data: [25, 60, 50, 60, 35, 50, 70],
    },
    {
      id: 3,
      name: "MoonRock",
      price: 0.004907,
      logo: "moonrock.png",
      increase: true,
      data: [40, 35, 40, 25, 50, 70, 45],
    },
    {
      id: 4,
      name: "NinjaFloki",
      price: 0.000123,
      logo: "ninjafloki.png",
      increase: true,
      data: [45, 35, 40, 30, 25, 45, 35],
    },
  ];
  const recents = [
    {
      id: 1,
      name: "MetaCraft",
      price: 0.0608,
      logo: "metacraft.png",
      increase: false,
      data: [40, 50, 45, 60, 35, 40, 45],
    },
    {
      id: 2,
      name: "Frog",
      price: 0.5875,
      logo: "frog.png",
      increase: false,
      data: [25, 50, 45, 48, 40, 60, 45],
    },
    {
      id: 3,
      name: "Musk Doge",
      price: 0.04041,
      logo: "musk-doge.png",
      increase: true,
      data: [25, 35, 60, 45, 50, 45, 45],
    },
    {
      id: 4,
      name: "2SHARE",
      price: 1366.24,
      logo: "2share.png",
      increase: true,
      data: [35, 30, 60, 50, 35, 45, 40],
    },
  ];

  const currencies = [
    {
      img: "country-icon/eng.png",
      name: "USD",
    },
  ];

  const cryptoSelected = {
    img: "crypto-icon/bitcoin.png",
    name: "BTC",
  };
  const cryptocurrencies = [
    {
      img: "crypto-icon/bitcoin.png",
      name: "BTC",
    },
  ];

  const currencySelected = {
    img: "country-icon/eng.png",
    name: "USD",
  };

  const steps = [
    {
      img: "sign-up.png",
      title: "Sign Up",
      description:
        "Sign up for your free NEFA Wallet on web, iOS or Android and follow our easy process to set up your profile",
    },
    {
      img: "fund.png",
      title: "Fund",
      description:
        "Choose your preferred payment method such as bank transfer or credit card to top up your NEFA Wallet",
    },
    {
      img: "buy-crypto.png",
      title: "Buy Crypto",
      description:
        "Buy Bitcoin or Ethereum, then securely store it in your Wallet or send it on easily to your friends anywhere",
    },
  ];

  const accordions = [
    {
      title: "Why should I choose NEFA?",
      description:
        "We're industry pioneers, having been in the cryptocurrency industry since 2016. We've facilitated more than 21 billion USD worth of transactions on our exchange for customers in over 40 countries. Today, we're trusted by over 8 million customers around the world and have received praise for our easy-to-use app, secure wallet, and range of features.",
    },
    {
      title: "How secure is NEFA?",
      description:
        "We're industry pioneers, having been in the cryptocurrency industry since 2016. We've facilitated more than 21 billion USD worth of transactions on our exchange for customers in over 40 countries. Today, we're trusted by over million customers around the world and have received praise for our easy-to-use app, secure wallet, and range of features.",
    },
    {
      title: "Do I have to buy a whole Bitcoin?",
      description:
        "We're industry pioneers, having been in the cryptocurrency industry since 2016. We've facilitated more than 21 billion USD worth of transactions on our exchange for customers in over 40 countries. Today, we're trusted by over million customers around the world and have received praise for our easy-to-use app, secure wallet, and range of features.",
    },
    {
      title: "How do I actually buy Bitcoin?",
      description:
        "We're industry pioneers, having been in the cryptocurrency industry since 2016. We've facilitated more than 21 billion USD worth of transactions on our exchange for customers in over 40 countries. Today, we're trusted by over million customers around the world and have received praise for our easy-to-use app, secure wallet, and range of features.",
    },
  ];

  return (
    <div className="App">
      <DefaultLayout>
        {/* <!-- Hero section --> */}
        <section id="hero" className="w-full pb-24">
          <BaseSection>
            <div className="col-span-12 lg:col-span-6 mt-12 xl:mt-10 space-y-4 sm:space-y-6 px-6 text-center sm:text-left">
              <span
                data-aos="fade-right"
                data-aos-once="true"
                className="text-base text-gradient font-semibold uppercase"
              >
                Welcome to Intuition AI
              </span>
              <h1
                data-aos="fade-right"
                data-aos-once="true"
                className="text-[2.5rem] sm:text-5xl xl:text-6xl leading-normal font-bold capitalize sm:pr-8 xl:pr-10"
              >
                Transforming{" "}
                Business Through <br/>
                <span className="text-header-gradient">AI Intelligence</span>{" "}
              </h1>
              <p
                data-aos="fade-down"
                data-aos-once="true"
                data-aos-delay="300"
                className="paragraph text-justify hidden sm:block"
              >
                At Intuition AI, we are at the forefront of revolutionizing how organizations interact with and leverage data. Our mission is simple: to empower businesses with intelligent, AI-driven solutions that automate decision-making, enhance operational efficiency, and simplify complex processes.
              </p>
              <p data-aos="fade-down"
                data-aos-once="true"
                data-aos-delay="300"
                className="paragraph text-justify hidden sm:block">
                By seamlessly integrating diverse data sources and utilizing advanced AI models, we help organizations turn data into actionable insights, driving innovation and sustainable growth.
              </p>
            </div>
            <div className="hidden sm:block col-span-12 lg:col-span-6">
              <div className="w-full">
                <img
                  data-aos="fade-up"
                  data-aos-once="true"
                  src={require("./assets/img/intution.png")}
                  className="-mt-4"
                  alt="hero image"
                />
              </div>
            </div>
            <img
              data-aos="fade-up"
              data-aos-delay="300"
              src={require("./assets/img/pattern/ellipse-1.png")}
              className="hidden sm:block absolute bottom-12 xl:bottom-16 left-4 xl:left-0 w-6"
            />
            <img
              data-aos="fade-up"
              data-aos-delay="300"
              src={require("./assets/img/pattern/ellipse-2.png")}
              className="hidden sm:block absolute top-4 sm:top-10 right-64 sm:right-96 xl:right-[32rem] w-6"
            />
            <img
              data-aos="fade-up"
              data-aos-delay="300"
              src={require("./assets/img/pattern/ellipse-3.png")}
              className="hidden sm:block absolute bottom-56 right-24 w-6"
            />
            <img
              data-aos="fade-up"
              data-aos-delay="300"
              src={require("./assets/img/pattern/star.png")}
              className="hidden sm:block absolute top-20 sm:top-28 right-16 lg:right-0 lg:left-[30rem] w-8"
            />
          </BaseSection>
        </section>
        {/* <!-- Crypto statistic section --> */}
        <section data-aos="fade-up" className="max-w-screen-xl mx-2 sm:mx-auto px-4 sm:px-6 lg:px-0 py-6 pb-20 sm:py-8 rounded-[2.25rem] sm:rounded-xl bg-white shadow-lg sm:shadow-md transform lg:-translate-y-12">
          <div className="w-full flex flex-col lg:flex-row items-center justify-center">
            <LandingCryptoStatistic
              title="🔥 Data-Driven Intelligence"
              description="Our AI solutions connect the dots between diverse data streams, helping you make informed decisions that matter."
              style="xl:border-r border-gray-200 lg:px-8"
            />
            <LandingCryptoStatistic
              title="🚀 Business Optimization"
              description="We specialize in integrating state-of-the-art AI technology within your existing systems, ensuring optimized performance and scalability."
              style="xl:border-r border-gray-200 lg:px-8"
            />
            <LandingCryptoStatistic
              title="💎 Innovation at Our Core"
              description="We are committed to pushing the boundaries of AI technology, unlocking new possibilities for your business."
              style="lg:px-8"
            />
          </div>
        </section>

        {/* <!-- About us section --> */}
        <section className="w-full my-24" id={'aboutus'}>
          <BaseSection>
            <div className="col-span-12 lg:col-span-6 sm:hidden mb-8">
              <div className="w-full">
                <img
                  src={require("./assets/img/about.jpg")}
                  className="mt-4 sm:-mt-4"
                  alt=""
                />
              </div>
            </div>
            <div
              data-aos="fade-right"
              className="col-span-12 lg:col-span-6 mt-4 xl:mt-20 space-y-6 px-4"
            >
              <h2 className="text-4xl font-semibold sm:pr-8 xl:pr-12">
                About Us
              </h2>
              <p className="paragraph text-justify">
                Intuition AI is a forward-thinking, AI-driven company dedicated to revolutionizing how organizations interact with and utilize data. Our core mission is to empower businesses by delivering intelligent solutions that automate decision-making, enhance operational efficiency, and streamline complex processes. By connecting diverse data sources and leveraging advanced AI models, we provide actionable insights that drive innovation and growth.
              </p>
              <p className="paragraph text-justify">
We specialize in integrating cutting-edge AI with business ecosystems, enabling companies to gain deeper insights, optimize performance, and scale their capabilities. Intuition AI’s flexible platform adapts to the unique needs of our clients, ensuring tailored solutions for every challenge.
              </p>
              <p className="paragraph text-justify">
With a deep commitment to innovation and client success, we are transforming industries, unlocking potential, and setting the standard for AI in business.
              </p>
            </div>
            <div
              data-aos="fade-left"
              className="col-span-12 lg:col-span-6 hidden sm:block"
              v-bind="$attrs"
            >
              <div className="w-full">
                <img
                  src={require("./assets/img/about.jpg")}
                  className="mt-4 sm:-mt-4"
                  alt=""
                />
              </div>
            </div>
          </BaseSection>
        </section>

        {/* <!-- Credit card section --> */}
        <section className="w-full my-36 cover-gradient-2 sm:cover-gradient" id={"archi"}>
          <BaseSection data-aos="fade-down">
            <div className="flex flex-col items-center justify-center col-span-12 lg:col-span-7">
              <div className="w-full">
                <img
                  src={require("./assets/img/archi.png")}
                  className="w-[95%]"
                  alt=""
                />
              </div>
            </div>
            <div className="col-span-12 lg:col-span-5 space-y-6 px-4 sm:px-6 mt-20">
              <h2 className="text-4xl font-semibold">
                Architecture{" "}
              </h2>
              <p className="paragraph">
                Intuition AI's architecture is designed to be modular, scalable, and powerful. The platform is built around the following components:
              </p>
              <ul className="space-y-4 sm:space-y-2">
                <LandingListItem title="Core: The central engine that reasons about actions and retrieves critical information. It orchestrates the flow of data and decisions across the system.">
                  {" "}
                </LandingListItem>
                <LandingListItem title="Sync: The messaging platform that ensures seamless communication and synchronization between components, users, and external systems.">
                  {" "}
                </LandingListItem>
                <LandingListItem title="Action Repository: A comprehensive library of tools and API integrations designed to perform a wide range of actions, from automation to decision-making.">{" "}</LandingListItem>
                <LandingListItem title="Retrieval Module: A powerful tool that enables users to upload and process documents, making data easier to understand and act upon.">
                  {" "}</LandingListItem>
                <LandingListItem title="Document Processing Module: An advanced component designed to break down, analyze, and interpret documents to surface relevant insights."> {""}</LandingListItem>
              </ul>
              <p className="paragraph">
                This flexible, layered architecture ensures Intuition AI remains adaptable to client needs and future technological advancements.
              </p>
              {/*<BaseButton style="w-full sm:max-w-[240px] px-10 py-4 bg-inherit text-gradient border border-[#0c66ee] text-base">
                Join the waitlist
              </BaseButton>*/}
            </div>
          </BaseSection>
        </section>
        {/* <!-- Partners section --> */}
        <section id={"clientele"} className="bg-partner relative max-w-full sm:mx-6 my-24 shadow sm:rounded-2xl overflow-hidden">
          <div className="w-full px-6 sm:px-0 py-16 flex flex-col items-center justify-center space-y-4 text-center">
            <h3
              data-aos="flip-down"
              className="text-2xl text-neutral-800 font-semibold"
            >
              Our Clientele
            </h3>
            <div
              data-aos="fade-up"
              className="flex flex-wrap flex-row gap-6 items-center justify-center"
            >
              <img
                    src={require(`./assets/img/partner/aspire.png`)}
                    className="w-1/4 mx-auto"
                    alt=""
                  />
              <img
                    src={require(`./assets/img/partner/connectsecure.jpeg`)}
                    className="w-1/2 mx-auto"
                    alt=""
                  />
            </div>
          </div>
        </section>
        {/* <!-- Advanced trading tools section --> */}
        {/*<section className="bg-trading-tools relative max-w-full sm:mx-4 my-20 py-16 shadow rounded-2xl overflow-hidden">
          <div className="relative max-w-screen-xl px-4 sm:px-2 mx-auto grid grid-cols-12 gap-x-6">
            <div className="col-span-12 lg:col-span-6 sm:hidden">
              <div className="w-full sm:mt-20 xl:mt-0">
                <img
                  src={require(`./assets/img/advanced-trading-tools.webp`)}
                  className="w-full"
                  alt=""
                />
              </div>
            </div>
            <div
              data-aos="fade-right"
              className="col-span-12 lg:col-span-6 space-y-8 sm:space-y-6 px-4 sm:px-6 mt-8"
            >
              <h2 className="text-4xl font-semibold">
                Advanced Trading{" "}
                <span className="text-header-gradient">Tools</span>
              </h2>
              <div className="space-y-2">
                <h4 className="text-lg font-medium">
                  Professional Access, Non-stop Availability
                </h4>
                <p className="paragraph text-sm xl:text-base">
                  We provide premium access to crypto trading for both
                  individuals and institutions through high liquidity, reliable
                  order execution and constant uptime.
                </p>
              </div>
              <div className="space-y-2">
                <h4 className="text-lg font-medium">
                  A Range of Powerful Apis
                </h4>
                <p className="paragraph text-sm xl:text-base">
                  Set up your own trading interface or deploy your algorithmic
                  strategy with our high-performance FIX and HTTP APIs. Connect
                  to our WebSocket for real-time data streaming.
                </p>
              </div>
              <div className="space-y-2">
                <h4 className="text-lg font-medium">Customer Support</h4>
                <p className="paragraph text-sm xl:text-base">
                  Premium 24/7 support available to all customers worldwide by
                  phone or email. Dedicated account managers for partners.
                </p>
              </div>
              <div className="flex flex-col sm:flex-row">
                <BaseButton style="px-10 py-4 bg-inherit text-gradient border border-[#4A8FF6] text-base">
                  Get Started
                </BaseButton>
                <BaseButton style="bg-inherit text-[#4A8FF6] px-10 py-4 text-center underline rounded-full hover:shadow-none">
                  Learn More
                </BaseButton>
              </div>
            </div>
            <div
              data-aos="fade-left"
              className="col-span-12 lg:col-span-6 hidden sm:block"
            >
              <div className="w-full sm:mt-20 xl:mt-0">
                <img
                  src={require(`./assets/img/advanced-trading-tools.webp`)}
                  className="w-full"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>*/}
        {/* <!-- Industry-leading security section --> */}
        {/*<section className="w-full my-24">
          <div className="relative max-w-screen-xl px-8 mx-auto grid grid-cols-12 gap-x-6">
            <div data-aos="fade-right" className="col-span-12 lg:col-span-6">
              <div className="w-full">
                <img
                  src={require("./assets/img/industry-leading-security.webp")}
                  className="w-full"
                  alt=""
                />
              </div>
            </div>
            <div
              data-aos="fade-left"
              className="col-span-12 lg:col-span-5 space-y-8 sm:space-y-6 mt-8 xl:px-8"
            >
              <h2 className="text-4xl font-semibold">
                Industry-leading security from day one
              </h2>
              <ul className="space-y-8 sm:space-y-4">
                <LandingListItem title="Safety, security and compliance">
                  <p className="text-sm text-gray-700 leading-relaxed">
                    NEFA is a licensed New York trust company that undergoes
                    regular bank exams and is subject to the cybersecurity
                    audits conducted by the New York Department of Financial
                    Services.
                    <span className="underline">Learn more</span> about our
                    commitment to security.
                  </p>
                </LandingListItem>
                <LandingListItem title="Hardware security keys">
                  <p className="text-sm text-gray-700 leading-relaxed">
                    With NEFA you can secure your account with a hardware
                    security key via WebAuthn.
                  </p>
                </LandingListItem>
                <LandingListItem title="SOC Certifications">
                  <p className="text-sm text-gray-700 leading-relaxed">
                    NEFA is <span className="underline">SOC 1 Type 2</span> and
                    <span className="underline">SOC 2 Type 2</span> compliant.
                    We are the world’s first cryptocurrency exchange and
                    custodian to complete these exams.
                  </p>
                </LandingListItem>
              </ul>
            </div>
          </div>
        </section>*/}
        {/* <!-- Getting started section --> */}
        {/*<section className="bg-trading-tools relative max-w-full sm:mx-4 xl:mx-10 my-24 shadow sm:rounded-2xl overflow-hidden">
          <div className="w-full py-16 flex flex-col items-center">
            <h2
              data-aos="flip-down"
              className="text-3xl sm:text-4xl font-semibold text-center"
            >
              Get started in just a few minutes
            </h2>
            <div
              data-aos="fade-up"
              className="relative w-full flex flex-col lg:flex-row items-center justify-between space-y-12 lg:space-y-0 px-4 xl:px-10 mt-16 sm:mt-8"
            >
              {steps.map((step) => {
                return <LandingStep step={step} />;
              })}
              <img
                src={require("./assets/img/getting-started/arrow.png")}
                className="hidden lg:inline-block absolute top-32 left-64 xl:left-[22rem] w-24 xl:w-[9.5rem]"
                alt=""
              />
              <img
                src={require("./assets/img/getting-started/arrow.png")}
                className="hidden lg:inline-block absolute top-32 right-64 xl:right-[22rem] w-24 xl:w-[9.5rem]"
                alt=""
              />
            </div>
          </div>
        </section>*/}
        {/* <!-- FAQ section --> */}
        {/*<section className="w-full my-24">
          <BaseSection>
            <div
              data-aos="fade-right"
              data-aos-delay="150"
              className="col-span-12 lg:col-span-6"
            >
              <div className="w-full">
                <img
                  src={require("./assets/img/faq.webp")}
                  className="w-full"
                  alt=""
                />
              </div>
            </div>
            <div
              data-aos="fade-left"
              data-aos-delay="150"
              className="col-span-12 lg:col-span-6 px-4 sm:px-6 mt-8"
            >
              <span className="text-base text-gradient font-semibold uppercase mb-4 sm:mb-2">
                Support
              </span>
              <h2 className="text-3xl sm:text-4xl font-semibold mb-10 sm:mb-6">
                Frequently asked questions
              </h2>

              <ul className="shadow-box">
                {accordions.map((accordion: any) => {
                  return <BaseAccordion accordion={accordion} />;
                })}
              </ul>
            </div>
          </BaseSection>
        </section>*/}

        <div className="w-full my-10 flex justify-center">
          <a
            data-aos="flip-down"
            data-aos-delay="150"
            href="#"
            className="px-6 py-3 flex items-center space-x-2 bg-[#FAFAFA] hover:bg-gray-100 hover:shadow-md border border-[#DDDDDD] rounded-md text-gray-700"
          >
            <span>Back to top</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="h-4"
            >
              <path
                fillRule="evenodd"
                d="M11.47 2.47a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06l-6.22-6.22V21a.75.75 0 01-1.5 0V4.81l-6.22 6.22a.75.75 0 11-1.06-1.06l7.5-7.5z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        </div>
      </DefaultLayout>
    </div>
  );
}

export default App;
